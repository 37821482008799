// composables/useEcho.js
import Echo from "laravel-echo";
import Pusher from "pusher-js";
declare const window: any;

export const useEcho = () => {
  const startEcho = () => {
    const sanctumClient = useSanctumClient();

    if (process.client && !window.Echo) {
      window.Pusher = Pusher;
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: "-Uop7g.8H24qg",
        wsHost: "realtime-pusher.ably.io",
        wsPort: 443,
        disableStats: true,
        encrypted: true,
        forceTLS: false,
        cluster: "eu",
        authorizer: (channel: any) => {
          return {
            authorize: async (socketId: string, callback: Function) => {
              await sanctumClient("/broadcasting/auth", {
                method: "POST",
                body: {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
              })
                .then((response) => {
                  callback(false, response);
                })
                .catch((error) => {
                  callback(true, error);
                });
            },
          };
        },
      });
    }
  };

  const disconnectEcho = () => {
    if (process.client && window.Echo) {
      window.Echo.disconnect();
      window.Echo = null;
      window.Pusher = null;
    }
  };

  return {
    startEcho,
    disconnectEcho,
  };
};
